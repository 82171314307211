import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { enUS } from "@mui/material/locale";
import { importAll, themeOptions } from "../core/theme";
import _config from "./config.js";
// services
import { UI_LANGUAGE } from "../../scripts/services/l10n";
import { mergeDeep } from "../../scripts/services/utils";
// types
import type { PaletteMode } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import type { Config } from "../../scripts/types/config";

export { default as icons } from "../core/icons";
export { images, hasImage } from "../core/theme";

export const config = _config as Config;

importAll(require.context("./images", true, /\.(?:jpe?g|png|webp|avif|gif|svg|mp4)$/));

const l10nMuiTheme = {
	en: enUS,
} as const;

const getDefaultTheme = (themeMode: PaletteMode) => {
	const defaultTheme = createTheme({
		palette: {
			mode: themeMode,
		},
	});
	return createTheme({
		palette: {
			...defaultTheme.palette,
			primary: {
				main: "#008fff",
			},
			neutral: {
				main: (themeMode === "dark") ? grey[800] : grey[300],
				contrastText: (themeMode === "dark") ? grey[300] : grey[900],
			},
			danger: {
				main: "#ee0000",
				dark: "#bb0000",
			},
		},
	});
};

export let muiTheme: Theme;

export const refreshMuiTheme = (themeMode: PaletteMode) => {
	const defaultTheme = getDefaultTheme(themeMode);

	muiTheme = createTheme(mergeDeep(themeOptions, {
		palette: {
			...defaultTheme.palette,
			text: {
				primary: (themeMode === "dark") ? "#e0e0e0" : "#191919",
				secondary: (themeMode === "dark") ? "#949494" : "#828282",
			},
			background: {
				default: (themeMode === "dark") ? defaultTheme.palette.common.black : grey[100],
				paper: (themeMode === "dark") ? grey[900] : defaultTheme.palette.background.paper,
			},
			action: {
				disabledBackground: (themeMode === "dark") ? "rgba(255, 255, 255, 0.12)" : "#dcdcdc",
			},
		},
		components: {
			MuiTab: {
				styleOverrides: {
					root: {
						"color": defaultTheme.palette.primary.main,
						"&.Mui-selected": {
							color: defaultTheme.palette.primary.contrastText,
							backgroundColor: defaultTheme.palette.primary.main,
						},
					},
				},
			},
		},
	}), themeOptions, l10nMuiTheme[UI_LANGUAGE]);
};

export const getMuiTheme = (themeMode: PaletteMode) => {
	refreshMuiTheme(themeMode);
	return muiTheme;
};

refreshMuiTheme("light");
